import React, { useState, useEffect } from "react";
import enrolledClasses from "./enrolled.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../../Services";
import SkeletonLoad from "./TableLoad/load";
import {
    Error,
    Success,
    Success_Dark,
    SuccessSwal,
    warningSwal,
} from "../../../Util/Toast";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Filter from "./filter/filter";
import Loader from "../../../Components/loader/fallingLines";
import PaymentModal from "./generatePaymentLink/generate";
import swal from "sweetalert";
import { FaCopy } from "react-icons/fa";
import Moment from "react-moment";
function Tutor() {
    const Navigate = useNavigate();
    const [filter, setFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState({
        counter: 0,
        whether_Batch_filter: 0,
        whether_Course_filter: 0,
        whether_PreferredDays_filter: 0,
        whether_Age_filter: 0,
        whether_Time_filter: 0,
        whether_Payment_filter: 0,
    });
    const [Active, setActive] = useState("0");
    const [data, setData] = useState([]);
    const [enrollCourseId, setEnrollCourseId] = useState("");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const admindata = JSON.parse(localStorage.getItem("admindata"));

    // Filter States
    const [filterLoad, setFilterLoad] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const getAllCategories = () => {
        let data = JSON.stringify({ ...body, IsAddedToBatch: Active });
        Services.enrolled_List_Filter("POST", data, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                    setFilterLoad(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Enquires);
                } else if (response.Status === 0) {
                    warningSwal("warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const changePaymentStatus = (status, id, coursename) => {
        Services.paymentStatus(
            "PUT",
            JSON.stringify({ PaymentStatus: status, CourseName: coursename }),
            token,
            id
        )
            .then((response) => {
                setFilterLoad(false);
                if (response.Status === 1) {
                    SuccessSwal(
                        "Success",
                        "Marked Regitration as paid Successfully!"
                    );
                    getAllCategories();
                }
            })
            .catch((err) => {
                setFilterLoad(false);
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories();
        // eslint-disable-next-line
    }, [body]);

    const claimHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let EnrollCourseID = e.currentTarget.getAttribute("EnrollCourseID");
        let coursename = e.currentTarget.getAttribute("coursename");
        let body = {
            IsClaimed: 1,
            ClaimedBy: admindata.AdminUserID,
            CourseName: coursename,
        };
        swal("Are you sure Claim the User?", {
            icon: "warning",
            // className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    className: enrolledClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    setFilterLoad(true);
                    Services.Claim_Enroll_Course(
                        "PUT",
                        JSON.stringify(body),
                        token,
                        EnrollCourseID
                    )
                        .then((res) => {
                            if (res.Status === 1) {
                                SuccessSwal(
                                    "Success",
                                    "Student profile claimed successfully"
                                );
                                getAllCategories();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                            Error("something went Wrong Please try again");
                        });
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };

    const DetailsHandler = (e) => {
        let id = e.currentTarget.getAttribute(`id`);
        Navigate(`/Student-registration/details?id=${id}`);
    };
    const paidHandler = (e) => {
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("courseId");
        let coursename = e.currentTarget.getAttribute("coursename");
        swal(
            "Are You Sure you want to mark status as Paid ,the price per class will be  zero and will not be reflected towards revenue?",
            {
                icon: "warning",
                // className: detailsClass["logout-popup"],
                dangerMode: true,
                buttons: {
                    cancel: "Cancel",
                    catch: {
                        text: "Confirm",
                        value: "Logout",
                        className: enrolledClasses["cancel"],
                    },
                    defeat: false,
                },
            }
        ).then((value) => {
            switch (value) {
                case "Logout":
                    setFilterLoad(true);
                    changePaymentStatus(1, id, coursename);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const ClaimOnClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const copyToClipboard = (e) => {
        e.stopPropagation();
        const text = e.currentTarget.getAttribute("PaymentLink");

        navigator.clipboard.writeText(text).then(() => {
            Success_Dark("Copied");
        });
    };
    const NavHandler = (e) => {
        let buttonType = e.currentTarget.getAttribute("btn-type");
        setActive(buttonType);
        setLoading(true);
        setBody((prev) => ({ ...prev, IsAddedToBatch: buttonType }));
    };
    const copyHandler = (e) => {
        e.stopPropagation();
        let number = e.currentTarget.getAttribute("number");
        navigator.clipboard.writeText(number);
        Success_Dark("Copied to clipboard");
    };
    return (
        <Layout Active={"Enrolled-list"}>
            {filterLoad && <Loader />}
            {showFilter && (
                <Filter
                    setShowFilter={setShowFilter}
                    showFilter={showFilter}
                    setBody={setBody}
                    Active={Active}
                    setFilter={setFilter}
                    setFilterLoad={setFilterLoad}
                    body={body}
                />
            )}
            {showPaymentModal && (
                <PaymentModal
                    setShowPaymentModal={setShowPaymentModal}
                    showPaymentModal={showPaymentModal}
                    data={data}
                    enrollCourseId={enrollCourseId}
                    getAllCategories={getAllCategories}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Student Registrations</title>
            </Helmet>
            <div className={enrolledClasses["Container"]}>
                <div className={enrolledClasses["control-btns"]}>
                    <button
                        className={enrolledClasses["button"]}
                        onClick={() => setShowFilter(true)}>
                        Filter
                    </button>
                </div>
                {filter && (
                    <div className={enrolledClasses["filteredOptions"]}>
                        <div className={enrolledClasses["options"]}>
                            <span>Filters:</span>
                            {Object.keys(filter)
                                .map((key) => ({
                                    key: key,
                                    value: filter[key],
                                }))
                                .map((item) => (
                                    <div>
                                        <p>{item.key}</p>
                                        <p>{item.value}</p>
                                    </div>
                                ))}
                        </div>
                        <button
                            onClick={() => {
                                setFilter(false);
                                setBody({
                                    counter: 0,
                                    whether_Batch_filter: 0,
                                    whether_Course_filter: 0,
                                    whether_PreferredDays_filter: 0,
                                    whether_Age_filter: 0,
                                    whether_Time_filter: 0,
                                    whether_Payment_filter: 0,
                                    whether_claimedbyname_filter: 0,
                                    whether_parentname_filter: 0,
                                    whether_studentname_filter: 0,
                                });
                                getAllCategories();
                            }}>
                            Clear Filter
                        </button>
                    </div>
                )}
                <div className={enrolledClasses["Tutor"]}>
                    <h3>
                        Student Registrations{" "}
                        <button
                            onClick={() =>
                                Navigate("/Student-reigistration/Add")
                            }>
                            Add Registration
                        </button>
                    </h3>
                    <div className={enrolledClasses["Navigator"]}>
                        <button
                            btn-type="0"
                            className={
                                Active === "0" ? enrolledClasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            New Registration
                        </button>
                        <button
                            btn-type="1"
                            className={
                                Active === "1" ? enrolledClasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Batch Created
                        </button>
                    </div>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={enrolledClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        {admindata.Type === 5 && <th></th>}
                                        <th>Student Name </th>
                                        {/* <th>Student </th> */}
                                        <th>Parent Details </th>
                                        <th>Course </th>

                                        <th>Claimed By</th>
                                        <th>Registration </th>
                                        <th>Preferred Days</th>
                                        {/* <th>Preferred Times</th> */}
                                        <th>Payment Details</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr
                                            onClick={DetailsHandler}
                                            id={item.EnrollCourseID}>
                                            {admindata.Type === 5 && (
                                                <td style={{ width: "2rem" }}>
                                                    <input
                                                        onClick={
                                                            ClaimOnClickHandler
                                                        }
                                                        onChange={claimHandler}
                                                        type="checkbox"
                                                        disabled={
                                                            item.IsClaimed === 1
                                                        }
                                                        coursename={
                                                            item.CourseName
                                                        }
                                                        defaultChecked={
                                                            item.IsClaimed === 1
                                                        }
                                                        checked={
                                                            item.IsClaimed === 1
                                                        }
                                                        EnrollCourseID={
                                                            item.EnrollCourseID
                                                        }
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                {" "}
                                                {item.StudentName} <br />(
                                                {item.StudentAge} years)
                                            </td>
                                            {/* <td> {item.StudentName}</td> */}
                                            <td
                                                onClick={copyHandler}
                                                number={item.MobileNumber}>
                                                {item.ParentName}
                                                <br />
                                                <span
                                                    style={{
                                                        lineHeight: "2rem",
                                                    }}>
                                                    {item.CountryCode}{" "}
                                                    {item.MobileNumber}{" "}
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "inherit",
                                                            border: "none",
                                                            marginLeft:
                                                                "0.5rem",
                                                        }}
                                                        title="Copy"
                                                        number={
                                                            item.MobileNumber
                                                        }>
                                                        <FaCopy />
                                                    </button>
                                                </span>
                                            </td>

                                            <td style={{ width: "10rem" }}>
                                                {item.CourseName} <br />
                                                <span
                                                    style={{
                                                        lineHeight: "2rem",
                                                    }}>
                                                    ({item.BatchSize})
                                                </span>
                                            </td>

                                            <td>{item.ClaimedByName}</td>
                                            <td>
                                                <Moment format={"DD-MM-YYYY"}>
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                            {/* <td>{item.PreferredStartTimes}</td> */}
                                            <td
                                                style={{
                                                    maxWidth: "8rem",
                                                }}>
                                                {item.PreferredDays}
                                            </td>
                                            <td>
                                                ₹{item.PricePaid} <br />
                                                {item.PaymentStatus == 1 && (
                                                    <Moment
                                                        format={"DD-MM-YYYY"}
                                                        utc>
                                                        {
                                                            new Date(
                                                                item.PaymentDateTime
                                                            )
                                                        }
                                                    </Moment>
                                                )}
                                            </td>
                                            <td
                                                className={
                                                    enrolledClasses[
                                                        "payment-status"
                                                    ]
                                                }>
                                                {item.PaymentStatus == 0 ? (
                                                    <span
                                                        className={
                                                            enrolledClasses[
                                                                "pending"
                                                            ]
                                                        }>
                                                        <span>
                                                            Link Not Generated
                                                        </span>{" "}
                                                    </span>
                                                ) : item.PaymentStatus == 1 ? (
                                                    <span
                                                        className={
                                                            enrolledClasses[
                                                                "paid-status"
                                                            ]
                                                        }>
                                                        Paid
                                                    </span>
                                                ) : item.PaymentStatus == 2 ? (
                                                    <>
                                                        <span
                                                            className={
                                                                enrolledClasses[
                                                                    "link-generated"
                                                                ]
                                                            }>
                                                            Generated
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span
                                                            className={
                                                                enrolledClasses[
                                                                    "pending"
                                                                ]
                                                            }>
                                                            Payment Failed
                                                        </span>
                                                    </>
                                                )}
                                            </td>

                                            <td>
                                                <div
                                                    className={
                                                        enrolledClasses[
                                                            "action-btns"
                                                        ]
                                                    }>
                                                    {/* {(item.PaymentStatus ===
                                                        0 ||
                                                        item.PaymentStatus ===
                                                            1) && (
                                                        <button
                                                            className={
                                                                enrolledClasses[
                                                                    "generate"
                                                                ]
                                                            }
                                                            disabled={
                                                                item.PaymentStatus !==
                                                                0
                                                            }
                                                            courseId={
                                                                item.EnrollCourseID
                                                            }
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowPaymentModal(
                                                                    true
                                                                );
                                                                setEnrollCourseId(
                                                                    e.currentTarget.getAttribute(
                                                                        "courseId"
                                                                    )
                                                                );
                                                            }}>
                                                            Generate Link
                                                        </button>
                                                    )} */}

                                                    <div
                                                        div
                                                        className={
                                                            enrolledClasses[
                                                                "copy-link"
                                                            ]
                                                        }>
                                                        <button
                                                            className={
                                                                enrolledClasses[
                                                                    "paid"
                                                                ]
                                                            }
                                                            courseId={
                                                                item.EnrollCourseID
                                                            }
                                                            coursename={
                                                                item.CourseName
                                                            }
                                                            disabled={
                                                                item.PaymentStatus !==
                                                                    2 &&
                                                                item.PaymentStatus !==
                                                                    3 &&
                                                                item.PaymentStatus !=
                                                                    0
                                                            }
                                                            onClick={
                                                                paidHandler
                                                            }>
                                                            Mark as Paid
                                                        </button>
                                                        {item.PaymentStatus ===
                                                            2 && (
                                                            <button
                                                                className={
                                                                    enrolledClasses[
                                                                        "copy"
                                                                    ]
                                                                }
                                                                disabled={
                                                                    item.PaymentStatus !==
                                                                    2
                                                                }
                                                                onClick={
                                                                    copyToClipboard
                                                                }
                                                                PaymentLink={
                                                                    item.PaymentLink
                                                                }>
                                                                Copy
                                                            </button>
                                                        )}
                                                    </div>
                                                    {item.IsAddedToBatch ===
                                                        0 && (
                                                        <button
                                                            onClick={(E) => {
                                                                E.stopPropagation();
                                                                sessionStorage.setItem(
                                                                    "enrolled",
                                                                    JSON.stringify(
                                                                        item
                                                                    )
                                                                );
                                                                Navigate(
                                                                    `/edit-enroll/${item.EnrollCourseID}`
                                                                );
                                                            }}
                                                            style={{
                                                                border: "none",
                                                                backgroundColor:
                                                                    "inherit",
                                                                cursor: "pointer",
                                                            }}>
                                                            <AiFillEdit
                                                                size={18}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Data Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
